/**
 * Created by Ivan on 17.06.2024
 */

var PinballScene = cleverapps.FixedWidthScene.extend({
    ctor: function (mission) {
        this._super();
        this.mission = mission;
        this.pinball = mission.logic.pinball;

        this.pinball.on("updateProgress", this.createListener(this.updateProgress.bind(this)));
        this.pinball.on("givePrize", this.createListener(this.givePrize.bind(this)));
        this.pinball.on("missionCompleted", this.createListener(this.onCompleted.bind(this)));
        this.pinball.on("updateState", this.createListener(this.updateState.bind(this)));
        this.pinball.on("updateChips", this.createListener(this.updateChipsText.bind(this)));
        this.pinball.on("showForce", this.createListener(this.showForce.bind(this)));
        this.pinball.on("toggleProgressBarText", this.createListener(this.toggleProgressBarText.bind(this)));
        this.pinball.on("showTicketAmount", this.createListener(this.showTicketsMinimal.bind(this)));
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_PINBALL);
        this.createMachine();
        this.createHeader();
        this.createRollView();
        this.createGame();
        this.createFooter();
        this.windowOfferButton = new WindowOffer(this, {
            text: "Pinball.Offer",
            onClicked: function () {
                cleverapps.meta.display({
                    focus: "PinballOffer",
                    action: function (f) {
                        new MissionOfferWindow(cleverapps.offerManager.findOffer({ type: Offers.TYPES.PINBALL_PACK }));
                        cleverapps.meta.onceNoWindowsListener = f;
                    }
                });
            },
            offerType: Offers.TYPES.PINBALL_PACK,
            bundle: "pinball",
            badgeText: "SALE"
        });
        this.windowOfferButton.show();
        if (this.pinball.checkComplete()) {
            this.onCompleted();
        }

        cleverapps.audio.playSound(bundles.pinball.urls.pinball_start_effect);

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_OPEN);
    },

    showForce: function () {
        this.updateChipsText();
        this.updateState();
        cleverapps.meta.display({
            focus: "PinballForce",
            action: function (f) {
                cleverapps.forces.create(
                    this.rollView.buttons[2],
                    Forces.PINBALL,
                    {
                        actives: [
                            this.gameView
                        ],
                        highlights: [
                            this.machine
                        ]
                    }
                );
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    createMachine: function () {
        var styles = cleverapps.styles.PinballScene;
        var machine = this.machine = new cc.Scale9Sprite(bundles.pinball.frames.pb_bg_png);
        machine.setContentSize2(styles.machine);
        var background = new cc.Scale9Sprite(bundles.pinball.frames.pb_bg_blue_png);
        background.setPositionRound(styles.machine.background);
        background.setLocalZOrder(-1);
        machine.addChild(background);
        this.addChild(machine);
        background.setContentSize2(styles.machine.background);
        machine.setPositionRound(styles.machine);
    },

    createRollView: function () {
        var styles = cleverapps.styles.PinballScene.rollView;
        var rollView = this.rollView = new PinballRollView({
            onClicked: function (touch) {
                if (this.pinball.isAdAvailable()) {
                    this.pinball.acceptChipsOffer();
                } else if (this.pinball.isGameAvailable()) {
                    this.onClicked(touch);
                    cleverapps.audio.playSound(bundles.pinball.urls.pinball_round_start_effect);
                }
            }.bind(this)
        });
        this.machine.addChild(rollView);
        this.rollView.setPositionRound(styles);
    },

    playIntroActions: function (f) {
        this.updateState();
        f();
    },

    createHeader: function () {
        var styles = cleverapps.styles.PinballScene.header;

        var header = this.header = new cc.Sprite(bundles.pinball.frames.header_png);
        this.machine.addChild(header, -1);
        header.setPositionRound(styles);

        var chipsText = this.chipsText = new TextWithIcon("%%" + this.mission.currency, {
            font: cleverapps.styles.FONTS.PINBALL_CHIPS_TEXT,
            margin: styles.text.margin,
            icons: {
                "%%": bundles.pinball.frames.pb_token_png
            },
            iconMaxHeight: 1.5
        });
        chipsText.fitTo(styles.text.maxWidth);
        header.addChild(chipsText);
        chipsText.setPositionRound(styles.text);
        var offerButton = this.offerButton = new AdsButtonView(this.pinball);
        header.addChild(offerButton);
        offerButton.setVisible(false);
        offerButton.setPositionRound(styles.text);
        cleverapps.aims.registerTarget("pinballChips", chipsText);
        this.updateChipsText();
    },

    createFooter: function () {
        var styles = cleverapps.styles.PinballScene.footer;

        var footer = this.footer = new cc.Sprite(bundles.pinball.frames.pb_bg_wood_png);
        this.gameView.addChild(footer, 1);
        footer.setPositionRound(styles);

        var progress = this.progress = new ScaledProgressBar({
            progress: bundles.pinball.frames.progress_bar_png,
            background: bundles.pinball.frames.progress_bar_bg_png,
            barText: {
                text: " "
            }
        });
        progress.setLength(styles.progress.width);
        progress.setGoal(Pinball.TICKETS_GOAL);
        progress.setPercentage(this.mission.result);
        footer.addChild(progress);
        progress.setPositionRound(styles.progress);

        var prize = new cc.Sprite(bundles.pinball.frames.pb_ticket_png);
        progress.addChild(prize);
        prize.setPositionRound(styles.progress.prize);
        cleverapps.aims.registerTarget("ticket", prize);

        this.progressGoals = [];
        for (var index = 0; index < Pinball.GOALS.length; index++) {
            var goal = new cc.Sprite(Pinball.GOALS[index].icon);
            progress.addChild(goal);
            goal.setPositionRound(progress.width * Pinball.GOALS[index].percent, progress.height / 2);
            this.progressGoals.push(goal);

            cleverapps.tooltipManager.create(goal, {
                rewards: Pinball.GOALS[index].prize,
                text: "Reward",
                size: cleverapps.styles.UI.Tooltip.SIZE.short
            });

            if (index < this.pinball.currentGoal) {
                goal.setVisible(false);
            }
        }
    },

    toggleProgressBarText: function () {
        this.progress.barText.getString() === " " ? this.progress.updateBarText("%from%   ") : this.progress.updateBarText(" ");
    },

    createGame: function () {
        var fieldSize = this.fieldSize = {
            width: PinballGame.FIELD_WIDTH * resolutionScale,
            height: PinballGame.FIELD_HEIGHT * resolutionScale
        };

        var gameView = this.gameView = new PinballGameView(this.pinball.game, fieldSize);
        this.machine.addChild(gameView);
        gameView.setPositionRound(cleverapps.styles.PinballScene.field);
    },

    givePrize: function (prizeIndex, callback) {
        this.progressGoals[prizeIndex].runAction(
            new cc.Spawn(
                new cc.ScaleTo(2).easing(cc.easeBackIn()),
                new cc.FadeOut(2)
            )
        );

        cleverapps.meta.display({
            focus: "pinballRewardWindow",
            action: function (f) {
                new RewardWindow(Pinball.GOALS[prizeIndex].prize, { event: cleverapps.EVENTS.EARN.MISSION });
                cleverapps.meta.onceNoWindowsListener = function () {
                    callback();
                    f();
                };
            }
        });
    },

    updateProgress: function (progress, callback) {
        this.progress.setPercentage(progress, {
            animated: true,
            callback: callback
        });
    },

    updateChipsText: function (delta) {
        var styles = cleverapps.styles.PinballScene.header.deltaAnimationPosition;
        this.offerButton.updateState();
        this.chipsText.setString("%%" + this.mission.currency);
        if (delta) {
            this.chipsText.stopAllActions();
            this.chipsText.runAction(new cc.Sequence(
                new cc.DelayTime(0.6),
                new cc.Show(),
                AnimationsLibrary.animateDelta(
                    delta,
                    {
                        target: this.chipsText,
                        x: styles.x,
                        t: styles.y,
                        font: cleverapps.styles.FONTS.PINBALL_CHIPS_TEXT
                    }
                )
            ));
        } else {
            this.chipsText.setVisible(!this.pinball.isAdAvailable());
        }
    },

    onStartGame: function () {
        this.updateChipsText();
        this.rollView && this.rollView.disable();
    },

    onClicked: function (touch) {
        if (cleverapps.forces.isRunningForce()) {
            cleverapps.forces.closeRunningForce();
        }

        var pos = this.gameView.convertTouchToNodeSpace(touch);
        pos.y = this.gameView.height - cleverapps.styles.PinballScene.ball.offsetY;
        var absolutePosition = cc.p(pos.x / this.gameView.width, pos.y / this.gameView.height);
        this.pinball.startGame(cc.p(absolutePosition.x * PinballGame.FIELD_WIDTH, absolutePosition.y * PinballGame.FIELD_HEIGHT - 30));

        this.onStartGame();
    },

    updateState: function () {
        this.offerButton.updateState();
        if (!this.mission.isCompleted()) {
            this.rollView.enable();
        } else {
            this.rollView.disable();
        }
    },

    scaleGameField: function () {
        var styles = cleverapps.styles.PinballScene;
        this.machine && cleverapps.UI.inflateToBoundaries(this.machine, [], {
            maxScale: 1,
            padding: styles.padding[cleverapps.resolution.mode]
        });
        this.machine.setPositionRound(styles.machine);
    },

    showTicketsMinimal: function (amount) {
        this.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                var dialogue = this.dialogue = new MinimalDialogue({
                    text: cleverapps.UI.generateOnlyText(
                        "Pinball.TicketsAmountMessage",
                        cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT,
                        { n: amount }
                    ),
                    person: true,
                    target: this.footer,
                    rects: [
                        this.footer.getSceneBoundingBox()
                    ]
                });
                this.addChild(dialogue);
                dialogue.display();
            }.bind(this)),
            new cc.DelayTime(2),
            new cc.CallFunc(function () {
                this.dialogue.remove();
                this.dialogue = undefined;
            }.bind(this))
        ));
    },

    onCompleted: function (callback) {
        var styles = cleverapps.styles.PinballScene.footer.completedText;

        this.rollView.disable();

        var text = cleverapps.UI.generateOnlyText("Pinball.completedText", cleverapps.styles.FONTS.WHITE_TEXT);
        this.footer.addChild(text);
        text.setPositionRound(styles);
        text.setCascadeOpacityEnabledRecursively(true);
        text.setOpacity(0);
        this.windowOfferButton.expireOffer();

        this.progress.runAction(new cc.Sequence(
            new cc.ScaleTo(1, 0).easing(cc.easeBackIn()),
            new cc.DelayTime(1),
            new cc.CallFunc(callback),
            new cc.RemoveSelf()
        ));
        text.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.FadeIn(1)
        ));
    },

    setupChildren: function () {
        this._super();
        this.windowOfferButton && this.windowOfferButton.update();
    },

    getBackgroundStyles: function () {
        return {
            bundle: "pinball_bg",
            backgroundId: "background"
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.pinball.urls.music,
            delay: 1.0
        };
    },

    listBundles: function () {
        return ["pinball", "pinball_bg", "game"];
    },

    closeAction: function () {
        this.pinball.renew();
        this.dialogue && this.dialogue.remove();
        this._super();
    }
});

PinballScene.BARRIER_IMAGE = {
    default: bundles.pinball.jsons.hook_json,
    left: bundles.pinball.frames.left_barrier_png,
    right: bundles.pinball.frames.right_barrier_png,
    romb: bundles.pinball.frames.romb_barrier_png,
    left_divider: bundles.pinball.frames.left_wanddown_png,
    right_divider: bundles.pinball.frames.right_wanddown_png
};

cleverapps.styles.PinballScene = {
    padding: [
        {
            x: 10,
            y: 150
        },
        {
            x: 10,
            y: 150
        },
        {
            x: 10,
            y: 0
        }
    ],

    machine: {
        x: { align: "center" },
        y: { align: "center", dy: -50 },
        width: 953,
        height: 1100,
        background: {
            x: { align: "center", dx: 2 },
            y: { align: "center", dy: 90 },
            width: 819,
            height: 780
        }
    },

    header: {
        x: { align: "center" },
        y: { align: "top", dy: 150 },

        billet: {
            x: { align: "center" },
            y: { align: "top", dy: 31 },
            width: 650,
            height: 60
        },

        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -29 },
            maxWidth: 200,
            margin: 10
        },
        deltaAnimationPosition: {
            x: {
                align: "center",
                dx: 0
            },
            y: {
                align: "bottom",
                dy: -45
            }
        }
    },

    footer: {
        x: { align: "center", dx: 2 },
        y: { align: "bottom", dy: -162 },

        billet: {
            x: { align: "center" },
            y: { align: "bottom", dy: -90 },
            width: 650,
            height: 120
        },

        progress: {
            x: { align: "center", dx: -5 },
            y: { align: "bottom", dy: 34 },
            width: 750,
            prize: {
                x: { align: "left", dx: -30 },
                y: { align: "center" }
            }
        },

        completedText: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -40 }
        }
    },

    field: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 73 }
    },

    currency: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -200 }
    },

    ball: {
        offsetY: 10
    },

    rollView: {
        x: { align: "center", dx: 0 },
        y: { align: "top", dy: 0 }
    }
};
