/**
 * Created by mac on 2/25/20
 */

var TriPeaks = function (level, options) {
    TileGame.call(this, level, options);

    this.executedMarks = [];

    var save = this.savedGame;

    if (cleverapps.Combo.isAvailable()) {
        this.combo = new cleverapps.Combo(this, save.combo);
    }

    this.hand = new Hand(this, save.hand || level.content.hand);

    this.wildcardBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_WILDCARD);

    if (this.wildcardBooster.wildcardTutorial) {
        this.wildcardBooster.wildcardTutorial = false;
    }

    if (this.actionsBefore.some(function (action) {
        return action.source;
    })) {
        this.dice = new Dice();
    }

    this.counter.registerStage(3, this.processMarks.bind(this));
    this.counter.registerStage(25, this.spawnCombo.bind(this));
};

var Game = TriPeaks;

TriPeaks.prototype = Object.create(TileGame.prototype);
TriPeaks.constructor = TriPeaks;

TriPeaks.prototype.activateBoosters = function () {
    TileGame.prototype.activateBoosters.call(this);

    if (this.outcome === GameBase.OUTCOME_UNKNOWN && !cleverapps.environment.isSceneWithPreview()) {
        if (this.hand.isEmpty() && !(this.open.getCurrentCard() instanceof Wildcard)) {
            this.wildcardBooster.startHint(true);
        }
    }
};

TriPeaks.prototype.getInfo = function () {
    var info = TileGame.prototype.getInfo.call(this);

    info.hand = this.hand.getInfo();
    info.open = this.open.getInfo();
    info.page = this.pagination.getCurrent();

    if (this.combo) {
        info.combo = this.combo.getInfo();
    }

    return info;
};

TriPeaks.prototype.spawnCombo = function () {
    if (this.combo) {
        this.combo.spawn();
    }
};

TriPeaks.prototype.shiftWildcard = function (wildcard) {
    this.open.shift(wildcard);
    this.counter.trigger();
    cleverapps.userStatus.reportUserAction();

    this.wildcardBooster.stopHint();
    this.removeHint();
};

TriPeaks.prototype.isUndoRecommended = function () {
    return this.table.listMissed().length > 0;
};

TriPeaks.prototype.shiftCard = function (options) {
    options = options || {};
    if (this.open.getCurrentCard()) {
        this.table.animateMissed();
    }

    if (this.cardsTutorial && this.cardsTutorial.hintCard) {
        this.cardsTutorial.hintCard.trigger("hideTutorial");
    }

    if (!options.showUp && !options.creation) {
        options.undoRecommended = this.isUndoRecommended();
    }

    var card = this.hand.shift(this.table);
    this.open.shift(card, options.silent);

    card.trigger("hideTutorial");

    if (!options.creation) {
        cleverapps.audio.playSound(bundles.game.urls.card_effect);
    }

    if (!options.showUp && !options.creation) {
        if (this.combo) {
            this.spawnCombo();
            options.comboLevel = this.combo.level;
            options.resetAfterPrize = this.combo.resetAfterPrize;
            this.combo.reset();
        }
        if (this.table.streakCard) {
            this.table.streakCard.reset();
        }

        this.log.push(MovesLog.MOVE_SHIFT_CARD, options);

        this.move = Card.MOVES.SHIFT;
        this.table.updateFeatures(this.move);
        this.counter.trigger();
    }
};

TriPeaks.prototype.unShiftCard = function (card) {
    this.hand.push(card);
    if (card.isOpen()) {
        card.flip();
    }
    this.move = Card.MOVES.UNSHIFT;
    this.table.updateFeatures(this.move);
    this.counter.trigger();
};

TriPeaks.prototype.getInterceptor = function (card) {
    var interceptor = undefined;
    this.table.cards.forEach(function (tableCard) {
        var flagsOnCard = tableCard.findComponent(FlagsOnCard);
        if (flagsOnCard && tableCard.isOpen() && flagsOnCard.lives > 0 && flagsOnCard.flags.filter(function (flag) {
            return flagsOnCard.isEqualSuit(flag, card) && !(card instanceof WeakJokerCard);
        }).length > 0) {
            interceptor = flagsOnCard;
        }
    });

    return interceptor;
};

TriPeaks.prototype.playCard = function (card) {
    TileGame.prototype.playCard.call(this, card);

    var shouldIncreaseCombo = !card.feature || [CurtainCard, DoubleCard, IncDecCard, SharkCard, WeakJokerCard, SuitCard, DoubleCard].some(function (CardClass) {
        return card instanceof CardClass;
    });

    if (this.combo && shouldIncreaseCombo) {
        this.combo.increaseLevel(card.getSuit());
        if (this.combo.isFull()) {
            this.hand.hideExtra();
        }
    } else if (!this.combo) {
        if (!this.lastEffect) {
            this.lastEffect = 0;
        }
        var lastEffect = this.lastEffect + 1;
        if (!bundles.game.urls["combo_effect_" + lastEffect]) {
            lastEffect = 1;
        }
        cleverapps.audio.playSound(bundles.game.urls["combo_effect_" + lastEffect]);

        this.lastEffect = lastEffect;
    }

    if (this.table.streakCard && shouldIncreaseCombo && !this.table.isEmpty()) {
        this.table.streakCard.increaseLevel(card);
    }
};

TriPeaks.prototype.getCardForHint = function (forTutorial) {
    var hint = undefined;
    var missed = this.table.listMissed();
    if (missed.length) {
        if (forTutorial !== undefined) {
            var suitableCards = forTutorial.filter && missed.filter(function (card) {
                return this.cardsTutorial.isApplicable(card, forTutorial.filter);
            }.bind(this)) || [];

            hint = suitableCards.length ? suitableCards[0] : missed[0];
        } else {
            hint = missed[Math.floor(Math.random() * missed.length)];
        }
    } else if (this.hand.top() !== undefined && !this.table.isEmpty()) {
        hint = this.hand.top();
    }

    return hint;
};

TriPeaks.prototype.addJackpot = function (f, card) {
    var targetCard = card || this.table.cardsWithPossibilityOfPlacingUnderThem().pop();
    if (!targetCard) {
        f();
        return;
    }

    var jackpotCard = TileFactory.Create({
        feature: "jackpot",
        x: targetCard.x,
        y: targetCard.y,
        rotation: targetCard.getRotation()
    });
    jackpotCard.setOwner(targetCard.owner);

    this.table.insertBeforeCard(targetCard, jackpotCard);
    this.table.trigger("insertCard", jackpotCard);

    cleverapps.dataLoader.save(DataLoader.TYPES.JACKPOT_LAST_APPEAR_TIME, Date.now());

    this.counter.setTimeout(f, 3500);
};

TriPeaks.prototype.stop = function () {
    TileGame.prototype.stop.apply(this, arguments);

    this.hand.clear();

    if (this.combo) {
        this.combo.reset();
    }
};

TriPeaks.prototype.clearTable = function (f) {
    var cards = cleverapps.Random.shuffle(this.table.cards);

    var maxAnimationTime = 0;
    cards.forEach(function (card, index) {
        card.marks.forEach(function (mark) {
            mark.trigger("hide");
        });

        card.components.forEach(function (component) {
            component.onHide();
        });

        var time = card.onGetView().animateLose(cards.length - index);
        maxAnimationTime = Math.max(time, maxAnimationTime);
    });

    cleverapps.timeouts.setTimeout(function () {
        this.table.clear();
        f();
    }.bind(this), maxAnimationTime * 1000);
};

TriPeaks.prototype.needBonusByLeftMoves = function () {
    return this.outcome === GameBase.OUTCOME_VICTORY;
};

TriPeaks.prototype.animateBonus = function (f) {
    new ActionPlayer([
        this.clearTable.bind(this),

        function (f) {
            var scene = cleverapps.scenes.getRunningScene();
            scene.createBonusChest();

            this.hand.animateBonus(function () {
                scene.bonusChest.animationLeave(f);
            });
        }.bind(this)
    ]).play(f);
};

TriPeaks.prototype.showTutorialStep = function () {
    this.tutorialRunning = true;

    if (this.hand.showExtraTutorialStep()) {
        return;
    }

    this.tutorialRunning = false;

    TileGame.prototype.showTutorialStep.call(this);
};

TriPeaks.prototype.setMoves = function (moves, options) {
    this.hand.extra.hide();
    if (Game.currentGame.wildcardBooster) {
        Game.currentGame.wildcardBooster.stopHint();
    }
    this.counter.setTimeout(function () {
        this.hand.addExtra(moves, options || {});
    }.bind(this), 300);
};

TriPeaks.prototype.processMarks = function () {
    while (this.executedMarks.length > 0) {
        var mark = this.executedMarks.shift();
        mark.execute();
        if (this.counter.isActive()) {
            return;
        }
    }
};

TriPeaks.prototype.executeMark = function (mark) {
    this.executedMarks.push(mark);
};

TriPeaks.prototype.showScreen = function (f, silent) {
    cleverapps.meta.compound(f, [
        function (f) {
            var controls = ["opencards"];
            cleverapps.Boosters.listBoostersIds().forEach(function (id) {
                controls.push("hint_" + id);
            });

            cleverapps.meta.showControlsWhileFocused("opencards", silent);

            f = cleverapps.wait(2, f);

            this.table.updateSize();
            this.table.showCards(f, silent);
            this.hand.showUp(f, silent);
        }.bind(this),

        function (f) {
            if (!this.open.cards.length && cleverapps.environment.isGameScene()) {
                this.shiftCard({
                    showUp: true,
                    silent: silent
                });
            }
            this.flip(f, silent);
        }.bind(this)
    ]);
};

TriPeaks.prototype.beforeGameStart = function (f) {
    this.beginCards = this.countOfCardsToPlay();

    if (this.competition) {
        this.competition.start();
    }

    if (JackpotCard.isAvailable() && this.level.isCurrentLevel() && Math.random() < 0.1) {
        this.addJackpot(f);
    } else {
        f();
    }
};