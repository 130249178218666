/**
 * Created by Reda on 19.09.2024
 */

var PinballRollView = cc.Node.extend({
    ctor: function (options) {
        this._super();
        this.options = options || {};
        this.createRoll();
        this.createArrows();
    },

    createRoll: function () {
        var styles = cleverapps.styles.PinballRollView.roll;

        var roll = this.roll = new cleverapps.Spine(bundles.pinball.jsons.ball_json);
        roll.setAnimation(0, "animation", true);
        roll.setVisible(false);

        var rollContainer = new cc.Node();
        rollContainer.setContentSize2(styles.width, roll.height);
        rollContainer.addChild(roll);
        roll.setPositionRound(styles.ball);
        this.addChild(rollContainer);
        rollContainer.setPositionRound(styles);
    },

    createArrows: function () {
        var styles = cleverapps.styles.PinballRollView.buttons;
        var buttons = this.buttons = [];

        for (var index = 0; index < 5; index++) {
            var button = new cleverapps.Spine(bundles.pinball.jsons.arrow_json);
            button.disabled = true;
            cleverapps.UI.onClick(button, function (touch) {
                if (!button.disabled) {
                    this.options.onClicked && this.options.onClicked(touch);
                }
            }.bind(this));
            buttons.push(button);
        }

        var layout = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        layout.setPositionRound(styles);
        this.addChild(layout);
    },

    enable: function () {
        var styles = cleverapps.styles.PinballRollView.roll;
        this.buttons.forEach(function (button) {
            button.setVisible(true);
            button.setAnimation(0, "idle", true);
            button.disabled = false;
        });
        this.roll.stopAllActions();
        this.roll.spine.stopAllActions();
        this.roll.setVisible(true);
        this.roll.setPositionRound(styles.ball);

        this.roll.runAction(
            new cc.RepeatForever(
                new cc.Sequence(
                    new cc.MoveTo(
                        styles.rollAnimationDuration,
                        this.roll.getParent().width,
                        this.roll.getPosition().y
                    ).easing(cc.easeInOut(2)),
                    new cc.PlaySound(bundles.pinball.urls.ball_rolling_effect),
                    new cc.MoveTo(
                        styles.rollAnimationDuration,
                        0,
                        this.roll.getPosition().y
                    ).easing(cc.easeInOut(2)),
                    new cc.PlaySound(bundles.pinball.urls.ball_rolling_effect)
                )
            )
        );
        this.roll.spine.runAction(new cc.RepeatForever(
            AnimationsLibrary.blinkShader({
                brightness: 0.4,
                duration: 2
            })
        ));
    },

    disable: function () {
        this.buttons.forEach(function (button) {
            if (!button.disabled) {
                button.setVisible(false);
            }
            button.disabled = true;
        });
        if (this.roll) {
            this.roll.stopAllActions();
            this.roll.spine.stopAllActions();
            this.roll.setVisible(false);
        }
    }
});

cleverapps.styles.PinballRollView = {
    roll: {
        x: { align: "left", dx: -300 },
        y: { align: "top", dy: -70 },
        rollAnimationDuration: 2,
        width: 620,
        ball: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: -5 }
        }
    },
    buttons: {
        x: { align: "center", dx: 2 },
        y: { align: "top", dy: -130 },
        margin: 101
    }
};