/**
 * Created by Ivan on 17.06.2024
 */

var PinballMissionLogic = function (mission) {
    this.mission = mission;
    this.pinball = new Pinball(mission);
};

PinballMissionLogic.prototype.isForceAvailable = function () {
    return this.pinball.isForceAvailable();
};

PinballMissionLogic.prototype.showForce = function () {
    return this.pinball.showForce();
};

PinballMissionLogic.prototype.updateChips = function (amount) {
    this.mission.updateCurrency(amount);

    if (amount > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_RECEIVE_CHIPS, {
            value: amount
        });
    }

    this.pinball.onUpdateChips();
};

PinballMissionLogic.prototype.updateTickets = function (amount) {
    this.mission.update(amount);

    if (amount > 0) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.PINBALL_RECEIVE_TICKETS, {
            value: amount
        });
    }
};

PinballMissionLogic.prototype.getLevelReward = function () {
    return Game.currentGame.getNormalizedLevelReward(RewardsConfig.PinballLevelRewards, "match3");
};

PinballMissionLogic.prototype.isFinished = function () {
    return this.mission.result >= Pinball.TICKETS_GOAL;
};